import React, { Component, createRef } from "react";
import WheelComponent from "./wheel";
import { Box, Button, Typography, Grid, List, ListItem, ListItemText, Link } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "react-wheel-of-prizes/dist/index.css";
import "./styles.css";
import regionDescriptions from './regionDescriptions.json';
import regionCareServices from './regionCareServices.json';
import regionDescriptions2 from './regionDescriptions2.json';
import regionPlaces from './regionPlaces.json';
import regionRanks from './regionRanks.json';
import regionDescriptions3 from './regionDescriptions3.json';
import regionPlaces2 from './regionPlaces2.json';
import regionRatings from './regionRatings.json';
import regionDescriptions4 from './regionDescriptions4.json';
import regionPlaces3 from './regionPlaces3.json';
import regionNeuroteams from './regionNeuroteams.json';
import regionDescriptions5 from './regionDescriptions5.json';
import regionPlaces4 from './regionPlaces4.json';
import regionPlaces5 from './regionPlaces5.json';
import regionDescriptions6 from './regionDescriptions6.json';
import regionPlaces6 from './regionPlaces6.json';
import regionPharmacies from './regionPharmacies.json';
import regions from './regions.json';

import arrow from './assets/arrow.svg';
import health from './assets/health.png';
import clock from './assets/clock.png';
import math from './assets/math.png';
import account from './assets/account.png';
import place from './assets/place.png';
import pharmacy from './assets/pharmacy.png';
import facebook from './assets/facebook.png';
import doc from './assets/doc.png';


import linkedin from './assets/linkedin.png';

const theme = createTheme({
  palette: {
    primary: {
      main: "#E0AFAF",
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selectedRegion: "",
      activeStep: 0,
      isFinished: false,
    };
    this.wheelRef = createRef();
  }

  onFinished = (winner) => {
    const regionName = winner;
    this.setState({ selectedRegion: regionName });
    setTimeout(() => {
      this.setState({ show: true, isFinished: true });
    }, 1000); // 1-second pause
  };

  triggerSpin = () => {
    if (this.wheelRef.current) {
      this.wheelRef.current.spin();
      this.setState({ show: false, isFinished: false });
    }
  };

  handleNext = () => {
    this.setState({ activeStep: 1 }, () => {
      setTimeout(() => {
        this.triggerSpin();
      }, 100);
    });
  };

  handleRegionClick = (region) => {
    const regionName = region;
    this.setState({ selectedRegion: regionName, show: true, isFinished: true }, () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  };

  highlightPlatsText = (text) => {
    if (!text) return "";
    const parts = text.split(" ");
    return (
      <>
        <span style={{ color: '#E0AFAF', fontWeight: 'bold' }}>{parts[0]}</span> {parts.slice(1).join(" ")}
      </>
    );
  };

  highlightRegionName = (description, regionName) => {
    if (!description) return "";
    const regex = new RegExp(regionName, 'g');
    const parts = description.split(regex);
    return parts.reduce((acc, part, index) => {
      acc.push(part);
      if (index < parts.length - 1) {
        acc.push(<span key={index} style={{ color: '#E0AFAF', fontWeight: 'normal' }}>{regionName}</span>);
      }
      return acc;
    }, []);
  };

  render() {
    const segments = regions.map(region => region);
    const segColors = [
      "#FF0000", "#FFFFFF", "#FBEAE7", "#FF0000", "#FFFFFF", "#FBEAE7",
      "#FF0000", "#FFFFFF", "#FBEAE7", "#FF0000", "#FFFFFF", "#FBEAE7",
      "#FF0000", "#FFFFFF", "#FBEAE7", "#FF0000", "#FFFFFF", "#FBEAE7",
      "#FF0000", "#FFFFFF", "#FBEAE7"
    ];
    const textColors = [
      "#FFFFFF", "#000000", "#000000", "#FFFFFF", "#000000", "#000000",
      "#FFFFFF", "#000000", "#000000", "#FFFFFF", "#000000", "#000000",
      "#FFFFFF", "#000000", "#000000", "#FFFFFF", "#000000", "#000000",
      "#FFFFFF", "#000000", "#000000"
    ];

    const { selectedRegion, isFinished, show, activeStep } = this.state;

    const places = regionPlaces6[selectedRegion] ? regionPlaces6[selectedRegion].split(" av ") : ["", ""];

    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: { xs: '100%', md: '40%' }, margin: '0 auto' }}>
          {activeStep === 0 ? (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2, fontFamily: "'Exo', sans-serif" }}>
              <Box sx={{ width: "100%", textAlign: "center", p: 0 }}>
                <Box
                  className="description"
                  sx={{
                    maxWidth: "100%",
                    p: 0,
                    borderRadius: 1,
                    boxShadow: 1,
                    bgcolor: "white",
                  }}
                >
                  <Typography variant="h5" sx={{ bgcolor: "#FF0000", color: "#FFFFFF", p: 3, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                    Neurosjukvården är ett lotteri
                  </Typography>
                  <Typography variant="body1" sx={{ pt: 3, pb: 1, pl: 3, pr: 3, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                    Vem som helst kan få en neurologisk diagnos och det kan ske när som helst i livet. Av de hundratal neurologiska diagnoser som finns är en del medfödda, andra kommer senare under livet. Men om du blir sjuk är det faktiskt ett lotteri vilken behandling du kommer att få. Bor du på rätt ställe i Sverige har du dragit en vinstlott med god neurosjukvård, tidig diagnos, god behandling och rehabilitering. Bor du på fel ställe, har du fått en nitlott som kan innebära en katastrof för dig som drabbats. De stora regionala skillnaderna inom neurosjukvården går inte att motivera av medicinska skäl.
                  </Typography>
                  <Typography variant="body1" sx={{ pt: 1, pb: 3, pl: 3, pr: 3, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                    Snurra på lyckohjulet och se var du hamnar!
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  sx={{ 
                    mt: 2, 
                    p: "10px 30px", 
                    width: "100%", 
                    bgcolor: "#FF0000", 
                    color: "white", 
                    border: "2px solid #FFFFFF",
                    borderRadius: "50px",
                    boxShadow: "none",
                    fontSize: "32px",
                    fontWeight: "bold",
                    textTransform: "none",
                    '&:hover': {
                      bgcolor: "#FF0000",
                      boxShadow: "none",
                    }
                  }}
                  onClick={this.handleNext}
                >
                  Snurra!
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2, fontFamily: "'Exo', sans-serif" }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  {isFinished ? (
                    <Box sx={{ 
                      maxWidth: "100%", 
                      p: 0, 
                      borderRadius: 1, 
                      boxShadow: 1, 
                      bgcolor: "white" 
                    }}>
                      <Typography variant="h5" sx={{ bgcolor: "#FF0000", color: "#FFFFFF", p: 3, borderRadius: 1, fontSize: "28px", fontWeight: "bold", textAlign: "center" }}>
                        {selectedRegion}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions[selectedRegion], selectedRegion)}
                      </Typography>
                      <Button 
                        variant="contained" 
                        sx={{ 
                          mt: 2, 
                          p: "20px 30px", 
                          width: "100%", 
                          bgcolor: "#FF0000", 
                          color: "white",
                          borderRadius: "50px",
                          border: "2px solid #FFFFFF",
                          boxShadow: "none",
                          fontSize: "28px",
                          fontWeight: "bold",
                          textTransform: "none",
                          '&:hover': {
                            bgcolor: "#FF0000",
                            boxShadow: "none",
                          }
                        }} 
                        fullWidth
                      >
                        Så här ser det ut
                      </Button>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={health} alt="health" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Specialistvård
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionCareServices[selectedRegion]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions2[selectedRegion], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Ju snabbare du kommer till en specialist i neurologi, desto större är chansen till en tidig diagnos och behandling. För många neurologiska sjukdomar är det viktiga nycklar för att kunna bromsa sjukdomen innan patienten fått svåra funktionsnedsättningar och försämrad livskvalitet.
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left", fontStyle: "italic" }}>
                        ”Jag får inte träffa neurolog. Primärvården vill sköta allt, men min läkare har inte rätt kunskap inom neurologi. Har gjort en rad tester, men har inte fått någon diagnos. Och under tiden blir jag bara sämre.”
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightPlatsText(regionPlaces[selectedRegion])}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={clock} alt="clock" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Korrekt diagnos
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionRanks[selectedRegion]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions3[selectedRegion], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Det kan ta tid att ställa en neurologisk diagnos. Ibland vill primärvården avvakta med att skicka en patient vidare, därför att symtomen bedöms vara otydliga. Skillnaderna mellan regionerna kan dock inte förklaras av det. Ju tidigare diagnos, desto bättre prognos.
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left", fontStyle: "italic" }}>
                        ”Är fortfarande under utredning efter 6 år. Jag uppfyller inte alla krav för MS.”
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightPlatsText(regionPlaces2[selectedRegion])}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={math} alt="math" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Betyg på vården
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionRatings[selectedRegion]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions4[selectedRegion], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Efter diagnos är det avgörande med regelbunden kontakt för att fånga upp förändringar i sjukdomsförloppet, utvärdera behandlingsalternativ och planera framåt. Det är också viktigt att snabbt kunna få kontakt med din neurolog om du upptäcker försämringar i din sjukdom.
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left", fontStyle: "italic" }}>
                        ”Jag tycker att jag bara "lämnats" med min diagnos och mina behandlingar. Det är liksom ingen uppföljning i mitt mående eller någon rehabilitering.”
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightPlatsText(regionPlaces3[selectedRegion])}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={account} alt="account" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Eget neuroteam
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionNeuroteams[selectedRegion]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions5[selectedRegion], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Ett neuroteam är specialiserat på neurologiska diagnoser. Här ingår till exempel läkare, sjuksköterska, fysioterapeut och arbetsterapeut. De som har tillgång till team har tätare kontakt med neurolog, får rehabilitering i högre grad och är nöjdare med sin vård.
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left", fontStyle: "italic" }}>
                        ”Det är känns tryggt och är smidigt att via mejl eller telefon snabbt kunna få svar på frågor från sjuksköterskorna i teamet.”
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightPlatsText(regionPlaces4[selectedRegion])}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={pharmacy} alt="pharmacy" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Rehabiliteringsplan
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionPharmacies[selectedRegion]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions6[selectedRegion], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Rehabilitering är en central del av neurosjukvården. Den kan vara en väg tillbaka till arbete och ett aktivt liv. Den är också viktig för att bibehålla funktioner och därmed möjliggöra så hög livskvalitet som möjligt, så länge som möjligt.
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left", fontStyle: "italic" }}>
                        ”Jag får inte kontakt med neurolog och de fysioterapeuter jag haft kontakt med kan inget om neurologi.”
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightPlatsText(regionPlaces5[selectedRegion])}
                      </Typography>

                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={place} alt="place" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Plats
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "50px", fontWeight: "bold", textAlign: "center" }}>
                        {places[0]}
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        {` av ${places[1]}`}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Hur ser det ut i ditt eget
                        regioner? Klicka i listan för att se
                        situationen där du bor!
                      </Typography>
                      <List>
                        {regions.map((region, index) => (
                          <ListItem
                            button
                            key={index}
                            sx={{
                              paddingTop: '0px',
                              paddingBottom: '0px',
                              marginTop: '0px',
                              marginBottom: '0px',
                              color: region === selectedRegion ? '#E0AFAF' : 'inherit',
                            }}
                            onClick={() => this.handleRegionClick(region)}
                          >
                            <ListItemText primary={`${index + 1}. ${region}`} />
                          </ListItem>
                        ))}
                      </List>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                          <Link href="https://www.facebook.com/neurosweden" target="_blank">
                            <Box component="img" src={facebook} alt="facebook" sx={{ height: '80px', width: '80px', marginRight: '20px' }} />
                          </Link>
                          <Link href="https://www.linkedin.com/company/neurof%C3%B6rbundet/?originalSubdomain=se" target="_blank">
                            <Box component="img" src={linkedin} alt="linkedin" sx={{ height: '95px', width: '95px', marginLeft: '20px' }} />
                          </Link>
                        </Box>
                      </Box>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Dela med dig och låt andra testa!
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                        <Link href="https://neuro.se/om-oss/saa-tycker-vi/neurobarometern/" target="_blank">
                          <Box component="img" src={doc} alt="doc" sx={{ height: '100px', width: '100px' }} />
                        </Link>
                      </Box>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Läs hela rapporten!
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Den här rankingen bygger
                        på en underöskning som
                        Neuroförbundet gör årligen för
                        att uppmärksamma de regionala
                        skillnaderna i neurosjukvården.
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden", maxWidth: "100%" }}>
                      <WheelComponent
                        ref={this.wheelRef}
                        segments={segments}
                        segColors={segColors}
                        textColors={textColors}
                        winningSegment={null}
                        onFinished={this.onFinished}
                        primaryColor="#FF0000"
                        contrastColor="#FFFFFF"
                        buttonText=""
                        isOnlyOnce={false}
                        size={260} // Adjust the size for mobile view
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    );
  }
}

export default App;
